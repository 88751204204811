<template>
  <layoutContainer>
    <div>
      <!-- <p>queryParams:{{ queryParams }}</p> -->

      <div class="search-header">
        <div v-if="SearchInput" class="SearchInput">
          {{ SearchInput }}
          <i @click="$store.commit('ChangeSearchInput', '')" :class="'el-icon-error close-icon'"></i>
        </div>
        <checkRadioGroup title="承接开发" :list="BusinessList.softwareDevelopmentList"
          :activeIndex.sync="queryParams.businessId" keyname="name"></checkRadioGroup>
        <checkRadioGroup title="成员来源" :list="FamousInternetCompanyShortNameList"
          :activeIndex.sync="queryParams.companyIds" keyname="shortName"></checkRadioGroup>
      </div>

      <div class="conpany-list-box">
        <div class="company-item" v-for="item in companyList" :key="item.id">
          <teamListItem :item="item" />
        </div>
      </div>

      <center>
        <el-pagination background layout="prev, pager, next" :current-page.sync="queryParams.page"
          :page-size="queryParams.size" :total="queryParams.total" :page-sizes="[10, 20, 30, 40, 50, 100]"
          @current-change="currentChange">
        </el-pagination>
      </center>
    </div>
  </layoutContainer>
</template>

<script>
import { mapState } from 'vuex'
import { getTeamListApi } from '../../api/team'

export default {
  computed: {
    ...mapState([
      'UserInfo',
      'AreaList',
      'BusinessList',
      'CompanyList',
      'EducationList',
      'EmployeeDomainList',
      'SearchInput',
      'FamousInternetCompanyShortNameList'
    ])
  },
  data() {
    return {
      companyList: [], // 公司列表
      queryParams: {
        businessId: 0, //  0 走所有的业务类型 承接业务id，1-网站建设，2-app开发，3-小程序开发
        companyIds: 0, //  所在地区id
        page: 1, //页码
        size: 8 //页面大小
      }
    }
  },
  created() {
    // this.$store.commit('ChangeSearchInput', '')
    this.getCompanyList()
    // this.getCompanyDetail()
    // this.getCompanyCaseDetail()
  },
  methods: {
    getCompanyList() {
      const { businessId, companyIds, page, size } = this.queryParams
      let obj = { page, size }
      if (businessId) {
        obj.businessId = businessId
      }
      if (companyIds) {
        obj.companyIds = companyIds
      }
      if (this.SearchInput) {
        obj.keyWord = this.SearchInput
      }
      getTeamListApi(obj).then(res => {
        this.companyList = [...res.data.records]
        this.queryParams.page = res.data.current
        this.queryParams.size = res.data.size
        this.queryParams.total = res.data.total
      })
    },

    getCompanyCaseDetail() {
      // getCompanyCaseDetailApi({caseId: 1}).then(res => {
      //   console.log('%c res....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
      // })
    },
    currentChange(val) {
      this.queryParams.page = val
      this.getCompanyList()
    }
  },
  watch: {
    ['queryParams.businessId']() {
      this.getCompanyList()
    },
    ['queryParams.companyIds']() {
      this.getCompanyList()
    },
    SearchInput() {
      this.getCompanyList()
    },
    ['$route.query']: {
      immediate: true,
      handler() {
        const query = this.$route.query
        //    businessId: 0, //  0 走所有的业务类型 承接业务id，1-网站建设，2-app开发，3-小程序开发
        // companyIds: 0, 
        this.queryParams.businessId = query.business_id ? query.business_id : 0
        this.queryParams.companyIds = query.famous_company_id ? query.famous_company_id : 0
        log.JSON(this.queryParams)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.search-header {
  background: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 0;
}

.check-box {
  display: flex;
  margin-top: 10px;

  div {
    margin-right: 30px;
  }

  li {
    padding: 10px 30px;
  }

  .active {
    background: #55cbc4;
  }
}

.conpany-list-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  // border: 1px solid #000;
  > :nth-child(4n) {
    margin-left: 20px;
  }

  > :nth-child(4n-3) {
    margin-right: 20px;
  }

  > :nth-child(4n-2) {
    margin-right: 20px;
  }

  .company-item {
    border-radius: 8px;
    height: 350px;
    overflow: hidden;
    background: #fff;
    margin-bottom: 20px;

    img {
      width: 285px;
      height: 200px;
    }

    p {
      padding-left: 20px;
    }

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      height: 45px;
      line-height: 45px;
    }

    .text {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      height: 50px;
      line-height: 50px;
    }

    .address {
      height: 55px;
      line-height: 55px;
      color: #5ad3cd;
    }
  }
}

.text-button {
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;

  font-weight: 400;
  color: #ff724c;

  >span {
    width: 60px;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    background: rgb(255, 241, 238);
  }
}

.SearchInput {
  display: inline-block;
  margin-left: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  background: #5ad3cd;
  color: #fff;
  border-radius: 4px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;

  >i {
    position: absolute;
    color: #666666;
    font-size: 20px;
    right: -30px;
    top: 8px;
  }
}
</style>
